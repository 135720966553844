const actions = [
  'user.saveUser',
  'user.updateProfile',
  'user.updateWillData',
  'user.saveStory',
  'user.updateStory',
  'user.deleteStory',
  'user.createPersonalMessage',
  'user.deletePersonalMessage',
  'user.updatePersonalMessage',
  'user.savePersonalMessage',
  'user.createSendOffPlan',
  'user.deleteSendOffPlan',
  'user.updateSendOffPlan',
  'user.saveSendOffPlan',
  'user.createTeam',
  'user.deleteTeam',
  'user.updateTeam',
  'user.saveTeam',
  'team.addMember',
  'team.removeMember',
  'team.updateMember',
  'team.saveMember'
];

export default actions;
