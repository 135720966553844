<template>
  <div class="uploader-modal">
    <v-tabs grow v-model="activeTab" slider-color="#df4e9e" background-color="transparent">
      <v-tab>Uploads</v-tab>
      <v-tab>Search</v-tab>
      <v-tab>AI</v-tab>
      <v-tab>Emojis</v-tab>
    </v-tabs>
    <!-- <div class="uploader-modal__content" v-show="activeTab == 0">
      <div>Emojis WIP</div>
    </div> -->
    <div class="uploader-modal__content" v-show="activeTab == 0">
      <label class="uploader-modal__upload">
        <div v-if="localValue.body && !loading" class="uploader-modal__preview"
          :style="{ backgroundImage: localValue.body ? `url(${localValue.body.thumb.url})` : '' }" />
        <div v-else class="uploader-placeholder">
          <atom-spinner v-if="loading" :animation-duration="1000" :size="60" :color="'#df4e9e'" />
          <v-icon v-else>{{ icon }}</v-icon>
        </div>
        <input :disabled="disabled" class="uploader-input" type="file" @change="onFileChanged" />
      </label>
    </div>
    <div ref="searchResult" class="uploader-modal__content" v-show="activeTab == 1" v-infinite-scroll="fetchImages">
      <div>
        <v-text-field v-model="query" placeholder="Type something..." solo>
          <template v-slot:append>
            <v-fade-transition group leave-absolute>
              <v-progress-circular v-if="busy" size="24" color="info" indeterminate key="0" />
              <v-btn @click="fetchImages" icon key="1">
                <v-icon>search</v-icon>
              </v-btn>
            </v-fade-transition>
          </template>
        </v-text-field>

        <div class="uploader-modal__search-result">
          <div @click="uploadSearchResult(image.thumb)" :style="{ backgroundImage: `url(${image.thumb})` }"
            class="uploader-modal__search-item" v-for="(image, index) in images" :key="index">
            <div class="open-original-icon pa-0" @click.stop="openImage(image.large)">
              <v-icon dense>open_in_new</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uploader-modal__content" v-show="activeTab == 2">
      <v-textarea filled dense v-model="aiPrompt"
        placeholder="Enter a text prompt to generate an image..."></v-textarea>
      <v-btn x-small text depressed color="blue" @click="generateImage">
        <v-icon x-small class="mr-2">mdi-creation</v-icon>
        Generate Image
      </v-btn>
      <div v-if="loading" class="loading-spinner">
        <v-progress-circular indeterminate color="blue" size="24"></v-progress-circular>
      </div>
      <div v-if="generatedImageUrl" class="generated-image">
        <img :src="displayImageUrl" alt="Generated Image" />
      </div>
    </div>
    <div class="uploader-modal__content" v-show="activeTab == 3">
      <v-select v-model="activeEmojiCategory" :items="Object.keys(emojiCategories)" label="Category"></v-select>
      <div class="emoji-list">
        <span v-for="emoji in emojiCategories[activeEmojiCategory]" :key="emoji" class="emoji-item"
          @click="uploadEmoji(emoji)">
          {{ emoji }}
        </span>
      </div>
      <div v-if="loading" class="loading-spinner">
        <v-progress-circular indeterminate color="blue" size="24"></v-progress-circular>
      </div>
    </div>
    <div class="uploader-modal__actions">
      <v-btn text small color="error" @click="remove">Remove</v-btn>
      <v-btn text small color="info" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
import * as _ from "underscore";
import { Upload } from "@/resources";
import { emojiCategories } from "@/components/_Global/Data/emojiData";

export default {
  name: "UploaderModal",
  data() {
    return {
      activeTab: 0,
      aiPrompt: "",
      generatedImageUrl: "",
      query: "",
      images: [],
      offset: 0,
      localValue: { ...this.value },
      busy: false,
      loading: false,
      emojiCategories: emojiCategories,
      activeEmojiCategory: "People",
      yOffset: -22,
    };
  },
  props: {
    disabled: Boolean,
    icon: String,
    url: String,
    value: Object,
    countBase64: Boolean,
    base64: String
  },
  computed: {
    displayImageUrl() {
      if (!this.generatedImageUrl) return '';
      
      // Check if it's already a data URL
      if (this.generatedImageUrl.startsWith('data:image')) {
        return this.generatedImageUrl;
      }
      
      // If it's just base64 data without the data URL prefix, add it
      return `data:image/png;base64,${this.generatedImageUrl}`;
    }
  },
  methods: {
    async generateImage() {
      this.loading = true;
      try {
        const response = await this.$http.post(
          "https://196.ignite.getforge.com/parse/functions/generateImage",
          { prompt: this.aiPrompt },
          {
            headers: {
              "X-Parse-Application-Id": "d431f3b12913c4fb93e37b70c5851f83",
              "Content-Type": "application/json",
            },
          }
        );
        this.generatedImageUrl = response.body.result;
      } catch (error) {
        console.error("Error generating image:", error);
      } finally {
        this.loading = false;
      }
    },
    remove() {
      this.$emit("input", null);
      this.$emit("close");
    },
    onFileChanged(event) {
      const file = event.target.files[0];

      const formData = new FormData();
      formData.append("attachment", file, file.name);

      this.loading = true;
      this.$http.post(this.url, formData).then(response => {
        this.loading = false;
        this.localValue = response.body;
      });
    },
    async save() {
      try {
        if (this.activeTab === 2 && this.generatedImageUrl) {
          this.loading = true;

          // Clean the base64 data
          const base64Data = this.generatedImageUrl.replace(/^data:image\/\w+;base64,/, "");
          const cleanedBase64 = base64Data.replace(/\s/g, ''); // Remove any whitespace

          // Convert base64 to Blob
          const blob = this.base64ToBlob(cleanedBase64, 'image/png');

          // Create a File object
          const file = new File([blob], "generated_image.png", { type: 'image/png' });

          // Create FormData and append the file
          const formData = new FormData();
          formData.append("attachment", file);

          // Upload the image using the Upload.image resource
          const response = await Upload.image({}, formData);

          this.localValue = response.body;

          if (this.countBase64 && this.localValue.body) {
            const base64 = await this.toDataUrl(this.localValue.body.url);
            this.$emit("update:base64", base64);
          }
          this.$emit("input", { ...this.localValue });
          this.$emit("close");
        } else {
          if (this.countBase64 && this.localValue.body) {
            const base64 = await this.toDataUrl(this.localValue.body.url);
            this.$emit("update:base64", base64);
          }
          this.$emit("input", { ...this.localValue });
          this.$emit("close");
        }
      } catch (error) {
        console.error("Error saving image:", error);
      } finally {
        this.loading = false;
      }
    },

    base64ToBlob(base64, mimeType) {
      try {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
      } catch (error) {
        console.error("Error converting base64 to Blob:", error);
        throw error;
      }
    },
    async uploadEmoji(emoji) {
      try {
        this.loading = true;

        // Create a canvas to draw the emoji
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 128;
        canvas.height = 128;

        ctx.font = "100px serif"; // Adjust font size if needed
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        const textMetrics = ctx.measureText(emoji);
        const x = canvas.width / 2;
        const y = canvas.height / 2 + textMetrics.actualBoundingBoxAscent / 2 + this.yOffset;
        ctx.fillText(emoji, x, y);

        // Convert the canvas to a data URL
        const dataUrl = canvas.toDataURL("image/png");

        // Convert the data URL to a blob
        const response = await fetch(dataUrl);
        const blob = await response.blob();

        // Convert the blob to a file
        const file = new File([blob], "emoji.png", { type: blob.type });

        // Create FormData and append the file
        const formData = new FormData();
        formData.append("attachment", file);

        // Upload the emoji image using the /uploads/image endpoint
        const uploadResponse = await Upload.image({}, formData);
        this.localValue = uploadResponse.body;

        if (this.countBase64 && this.localValue.body) {
          const base64 = await this.toDataUrl(this.localValue.body.url);
          this.$emit("update:base64", base64);
        }
        this.$emit("input", { ...this.localValue });
        this.$emit("close");
      } catch (error) {
        console.error("Error uploading emoji:", error);
      } finally {
        this.loading = false;
      }
    },
    // async save() {
    //   if (this.countBase64 && this.localValue.body) {
    //     const base64 = await this.toDataUrl(this.localValue.body.url);
    //     this.$emit("update:base64", base64);
    //   }
    //   this.$emit("input", { ...this.localValue });
    //   this.$emit("close");
    // },
    fetchImages: _.debounce(function () {
      if (!this.query)
        return false;
      this.busy = true;
      const joinedQuery = this.query.split(" ").join("_").toLowerCase();
      this.$http
        .get("vulcan/v1/azure/image-search", {
          params: { query: joinedQuery, offset: this.offset }
        })
        .then(response => {
          const { offset, images } = response.body;
          this.offset = offset;
          this.images = [...this.images, ...images];
          this.busy = false;
        });
    }, 300),
    uploadSearchResult(url) {
      this.activeTab = 0;
      this.loading = true;

      var request = new XMLHttpRequest();
      request.responseType = "blob";
      request.onload = () => {
        const formData = new FormData();
        formData.append(
          "attachment",
          request.response,
          this.query + "." + request.response.type.split("/")[1]
        );
        this.$http.post(this.url, formData).then(response => {
          this.localValue = response.body;
          this.loading = false;
        });
      };

      request.open("GET", url);
      request.send();
    },
    toDataUrl(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },
    openImage(url) {
      window.open(url, '_blank');
    }
  },
  watch: {
    query() {
      this.offset = 0;
      this.images = [];
    }
  }
};
</script>

<style scoped>
.uploader-input {
  display: none;
}

.uploader-modal {
  width: 400px;
  background: white;
  z-index: 4;
  position: absolute;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.uploader-modal__title {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
}

.uploader-modal__title>>>i {
  cursor: pointer;
}

.uploader-modal>>>.theme--light.v-tabs-bar {
  background: #f9f9f9;
}

.uploader-modal__content {
  padding: 15px;
  max-height: 280px;
  overflow: auto;
}

.uploader-modal__preview {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.uploader-modal__actions {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.uploader-modal__upload {
  display: block;
  width: 120px;
  height: 120px;
  border: 1px solid #e7e7e7;
  cursor: pointer;
  margin: auto;
  position: relative;
}

.uploader-modal__search-result {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.uploader-modal__search-item {
  margin: 10px;
  cursor: pointer;
  width: 95px;
  height: 65px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.uploader-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
}

.open-original-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border-radius: 0 0 0 2px;
  display: flex;
  visibility: hidden;
}

.uploader-modal__search-item:hover .open-original-icon {
  visibility: visible;
}

.open-original-icon:hover * {
  color: black !important;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.generated-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.generated-image img {
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.emoji-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.emoji-item {
  font-size: 32px;
  margin: 10px;
  cursor: pointer;
}
</style>
