<template>
  <v-toolbar dense no-padding>
    <Uploader width="70px" height="50px" :disabled="readonly" label="Bot Avatar" icon="image"
      v-model="localSettings.image" />

    <v-divider class="mr-2" vertical />

    <AiInputUtility :default-prompt="defaultPrompt" :insert-user-input="true" v-model="localSettings.name">
      <template v-slot="{ value, updateValue }">
        <v-text-field class="pa-0" hide-details :readonly="readonly" placeholder="Bot Name" :value="value"
          @input="val => updateValue(val)" />
      </template>
    </AiInputUtility>

    <v-divider class="ml-2" vertical />

    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="notes">Notes</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import { mapGetters } from 'vuex';
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";
import AiInputUtility from "@/components/_Global/AiInputUtility.vue";
import NotionEditor from "@/components/_Global/NotionEditor/NotionEditor";

export default {
  name: "Chatbot_EntryPointObjectPropertyEditor",
  extends: ObjectPropertyEditorExtend,
  components: {
    AiInputUtility
  },
  data() {
    return {
      openedData: null
    };
  },
  computed: {
    ...mapGetters({
      currentProject: 'project/active',
      currentChart: 'chart/active'
    }),
    defaultPrompt() {
      const projectName = this.currentProject ? this.currentProject.name : 'the project';
      const chartName = this.currentChart ? this.currentChart.name : 'the chart';
      return `Generate a name for a chatbot for project name: ${projectName} and the current chart titled:${chartName}. Return only the name, no other text.`;
    }
  },
  methods: {
    onOpenNotion() {
      this.localSettings = { notesLines: [], ...this.localSettings };

      this.$root.$emit("DataEditor.open", {
        component: NotionEditor,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        dataField: 'notesLines'
      });
    },
    onDataEditorClose() {
      this.openedData = null;
    }
  },
  watch: {
    openedData() {
      if (this.openedData === "notes") {
        this.onOpenNotion();
      } else {
        this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = null;
    }
  },
  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>