<template>
  <div class="chatbot__bot-message">
    <div
      class="chatbot__bot-message__message d-flex py-1"
      :class="{first: index == 0}"
      :key="message.id"
      v-for="(message, index) in localSettings.messages"
    >
      <v-icon
        class="chatbot__bot-message__delete-message ml-2"
        @click.stop="deleteMessage(message.id)"
      >close</v-icon>
      <v-textarea
        class="pa-0 my-0 ml-2"
        auto-grow
        rows="1"
        v-model="message.content"
        :readonly="readonly"
        hide-details
        dark
        placeholder="Type message"
      />
    </div>
    <div v-if="!readonly">
      <div class="d-flex align-center">
        <v-btn small text class="mr-2" color="primary" @click.stop="addMessage()">Add Variant</v-btn>
        <v-btn
          small
          color="blue"
          dark
          text
          @click="generateVariant"
          :loading="isGenerating"
          :disabled="localSettings.messages.length === 0"
        >
          <v-icon small v-if="!isGenerating">mdi-creation</v-icon>
          <template v-slot:loader>
            <span>Generating...</span>
          </template>
        </v-btn>
      </div>
    </div>

    <br />
    <div>
      <Controls
        :baseObject="baseObject"
        type="body"
        label="Body"
        class="chatbot__bot-message__body"
        :controls.sync="localSettings.bodyControls"
        group="body"
        :readonly="readonly"
      />
      <br />
      <Controls
        :baseObject="baseObject"
        type="actions"
        label="Actions"
        class="chatbot__bot-message__actions"
        :controls.sync="localSettings.actionsControls"
        group="actions"
        :readonly="readonly"
      />
    </div>
  </div>
</template>

<script>
import Base_TextObject from "../../Base/TextObject/Component";
import Controls from "@/components/_Common/ControlElements/Controls";
import ExtendsComponent from "../../_extends/ObjectComponent";

import { deepClone } from "@/lib";

export default {
  name: "Chatbot_BotMessageObject",
  extends: ExtendsComponent,
  components: {
    Base_TextObject,
    Controls,
  },
  data() {
    const localSettings = {
      messages: [],
      bodyControls: [],
      actionsControls: [],
      ...deepClone(this.settings)
    };
    return {
      localSettings,
      controlPickerOpened: false,
      controlPickerSectionName: null,
      isGenerating: false
    };
  },
  methods: {
    setModel() {
      const controls = []
        .concat(this.baseObject.info.settings.actionsControls || [])
        .concat(this.baseObject.info.settings.bodyControls || []);

      const models = controls
        .map(a => {
          if (a.settings.model)
            return a.settings.model;
        })
        .filter(a => a);

      const schema = {};
      models.forEach(e => schema[e] = true);
      this.$store.commit("intellisense/setModel", schema);
    },
    async generateVariant() {
      if (this.localSettings.messages.length === 0) return;

      this.isGenerating = true;
      try {
        const existingVariants = this.localSettings.messages.map(m => m.content).join('\n');
        const prompt = `Given the following message variants for a chatbot:\n\n${existingVariants}\n\nGenerate a new, unique variant that maintains the style and intent of the existing messages, but add a little bit of variation. Return only the variant text and no other text.`;

        const response = await this.$http.post(
          'https://196.ignite.getforge.com/parse/functions/bedrockClaude2Completions',
          { prompt },
          {
            headers: {
              'X-Parse-Application-Id': 'd431f3b12913c4fb93e37b70c5851f83',
              'Content-Type': 'application/json',
            },
          }
        );

        const newVariant = response.body.result.result.completion.trim();
        this.addMessage(newVariant);
      } catch (error) {
        console.error('Error generating variant:', error);
        // Optionally, show an error message to the user
      } finally {
        this.isGenerating = false;
      }
    },
    addMessage(content = '') {
      this.localSettings.messages.push({ id: this.guid(), content });
    },
    deleteMessage(id) {
      const index = this.localSettings.messages.findIndex(m => m.id == id);
      this.localSettings.messages.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.chatbot__bot-message {
  border: 4px solid #605da5;
  border-radius: 20px;
  padding: 20px;
  background: white;
  position: relative;
  width: 380px;
}
.chatbot__bot-message__message {
  background: #555fa6;
  border-radius: 5px;
  margin-bottom: 10px;
}
.chatbot__bot-message__delete-message {
  color: white !important;
  cursor: pointer;
}
.chatbot__bot-message__body {
  min-height: 90px;
}
.chatbot__bot-message__actions {
  min-height: 58px;
}
.chatbot__bot-message__body,
.chatbot__bot-message__actions {
  margin-top: 15px;
}
</style>
