import FilterObject from './FilterObject';
import BotMessageObject from './BotMessageObject';
import DialogflowObject from './DialogflowObject';
import RouterObject from './RouterObject';
import UserMessageObject from './UserMessageObject';
import EntryPointObject from './EntryPointObject';
import EndpointObject from './EndpointObject';
import RenderTemplateObject from './RenderTemplateObject';
import ActionObject from './ActionObject';
import MuralActionObject from './MuralActionObject';
import SetValueObject from './SetValueObject';

export default {
  objects: {
    EntryPointObject,
    FilterObject,
    BotMessageObject,
    DialogflowObject,
    RouterObject,
    UserMessageObject,
    EndpointObject,
    RenderTemplateObject,
    MuralActionObject,
    SetValueObject,
    ActionObject
  },
  tab: 'Chatbot'
}
