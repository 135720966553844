<template>
    <div class="ai-utility">
        <div class="input-wrapper" @mouseover="showIcon = true" @mouseleave="showIcon = false">
            <slot :value="inputValue" :updateValue="updateValue"></slot>
            <v-icon small color="blue"v-if="showIcon && !loading" class="ai-icon" @click="showPopover = true">mdi-creation</v-icon>
            <v-progress-circular v-if="loading" indeterminate color="blue" size="24"
                class="loading-spinner"></v-progress-circular>
        </div>
        <div v-if="showPopover" class="popover">
            <v-textarea filled dense v-model="prompt" placeholder="Ask AI..." class="ai-utility__textarea"></v-textarea>
            <v-select v-model="selectedModel" :items="models" label="Select Model" dense></v-select>
            <v-btn x-small text depressed color="blue" @click="generateContent">
                <v-icon x-small>mdi-creation</v-icon>
            </v-btn>
            <v-btn x-small text @click="showPopover = false">
                cancel
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        defaultPrompt: {
            type: String,
            default: 'Default prompt',
        },
        insertUserInput: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            showIcon: false,
            showPopover: false,
            prompt: this.defaultPrompt,
            selectedModel: 'useTextCompletionAPI-16',
            models: [
                { text: 'Claude 2', value: 'bedrockClaude2Completions' },
                { text: 'OpenAI 41106', value: 'useTextCompletionAPI-41106' },
                { text: 'OpenAI 16', value: 'useTextCompletionAPI-16' },
            ],
            loading: false,
            inputValue: this.value,
        };
    },
    methods: {
        updateValue(value) {
            this.inputValue = value;
            this.$emit('input', this.inputValue);
        },
        async generateContent() {
            this.loading = true;
            try {
                const userInput = this.$el.querySelector('input, textarea')?.value || '';
                const finalPrompt = this.insertUserInput ? `${this.prompt} ${userInput}` : this.prompt;
                const appId = "d431f3b12913c4fb93e37b70c5851f83";

                const response = await this.$http.post(
                    `https://196.ignite.getforge.com/parse/functions/${this.selectedModel}`,
                    { prompt: finalPrompt },
                    {
                        headers: {
                            'X-Parse-Application-Id': appId,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                let generatedContent = '';

                console.log(response.body.result.result);
                console.log(this.selectedModel);
                if (this.selectedModel === 'bedrockClaude2Completions') {
                    generatedContent = response.body.result.result.completion;
                } else {
                    generatedContent = response.body.result.result;
                }

                this.inputValue = generatedContent;
                this.$emit('input', this.inputValue);

                this.showPopover = false;
            } catch (error) {
                console.error('Error generating content:', error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.ai-utility {
    position: relative;
    display: inline-block;
    width: 100%;
}

.v-textarea textarea {
    font-size: 12px !important;
    line-height: 1.2 !important;
}

.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.ai-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    cursor: pointer;
    background: white;
}

.loading-spinner {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.popover {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    z-index: 1000;
}
</style>